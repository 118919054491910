.header {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .title {
    align-items: center;
    font-weight: bold;
    color: var(--text-color);
  }

  .cafe_name {
    display: flex;
    font-size: 1.2em;
    color: var(--text-color);
  }

  .cart-icon {
    display: flex;
    align-items: center;
    gap: 15px;
    color: var(--text-color);
    opacity: 0.7;
    transition: color 1s ease;

    .theme-toggle {
      background: none;
      border: none;
      cursor: pointer;
      color: var(--text-color);
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: color 1s ease;

      &:active {
        opacity: 0.6;
      }
    }
  }

  .back_button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--text-color);
  }

  .logo {
    position: absolute;
    top: -13px;
    left: 0;
    height: 0px;
    width: 0px;
  }
}

.headerHr {
  margin: 10px 0;
  border-bottom: 1px solid var(--hr-color);
  transition: border-bottom 1s ease;
}

[data-theme='dark'] .logo {
  filter: invert(0.8);
  opacity: 0.8;
  transition: filter 1s ease, opacity 1s ease;
}

[data-theme='light'] .logo {
  filter: invert(0);
  opacity: 1;
  transition: filter 1s ease, opacity 1s ease;
}
