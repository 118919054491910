.cart-item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  height: 90px;
  margin-bottom: 10px;
  background-color: var(--background-color-2);
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  cursor: pointer;

  .item__photo {
    height: 90px;
    width: 90px;
    overflow: hidden;
    border-radius: 16px 0 0 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      transition: filter 0.3s ease;

      [data-theme='dark'] & {
        filter: brightness(0.7);
      }
    }
  }

  .cart-item__details {
    padding: 10px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    height: 100%;
  }

  .cart-item-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    height: 100%;

    .cart-item__name {
      color: var(--text-color);
      font-weight: 500;
      text-align: left;
    }

    .cart-item__price {
      font-weight: 500;
      font-size: 14px;
      color: #42b748;
      text-align: left;
    }
  }

  .cart-item__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
  }

  .cart-item__controls {
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 16px;
    height: 26px;
    width: 74px;
    background-color: #42b748;

    .cart-item__quantity {
      width: 20px;
      text-align: center;
    }
  }

  .cart-item__button {
    color: #ffffff;
    border-radius: 50%;
    background-color: #42b748;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    border: none;
    padding: 5px 5px;
  }
}
