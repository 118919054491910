.navbar {
  padding-top: 10px;
  width: 96vw;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.navbar-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border-color);
  margin-right: 10px;
  border-radius: 10px;
  white-space: nowrap;

  &:last-child {
    padding-right: 5px;
    border: none;
  }
}

.navbar-link {
  background: none;
  border: none;
  text-decoration: none;
  color: var(--text-color);
  padding: 5px 10px;
  cursor: pointer;
  display: inline-block;
  font: inherit;
}
