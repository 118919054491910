.main__category {
  padding-top: 20px;
  .main__title {
    display: flex;
    font-weight: 500;
    align-items: center;
    font-size: 1.5em;
    margin: 20px 0;
    letter-spacing: 1px;
    color: var(--text-color);
  }
  .main__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 7px;
    margin-bottom: 15px;
  }
  &:first-child {
    padding-top: 0px;
  }
}

.fixed-upp-icon {
  position: fixed;
  bottom: 30px;
  right: -35px;
  z-index: 3;
  transition: right 0.45s ease-out;
  cursor: pointer;
}

.fixed-upp-icon.visible {
  right: 15px;
}
