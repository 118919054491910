.button-in-cart {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--background-color);
  border-radius: 25px;
  padding: 0 5px;

  &__button {
    background-color: inherit;
    color: var(--text-color);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
  }

  &__count {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 5px;
  }
}

.menu-item__button_in_cart {
  background-color: var(--background-color-3);
}
