.cart-page {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 10px 0;

    &__zakaz {
      font-size: 24px;
      letter-spacing: 1px;
      font-family: 'Inter', serif;
      font-weight: 700;
      color: var(--text-color);
    }

    .cart-clear {
      padding: 5px 0 5px 5px;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .cart-items {
    border-radius: 8px;
  }

  .prices {
    font-size: 12px;
  }

  .total-price {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color);
  }

  .cart-details {
    display: flex;
    flex-direction: column;

    .cart-detail {
      margin-bottom: 20px;

      .cart-detail-input-data {
        position: relative;
        display: flex;
        align-items: center;

        &__icon {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 30px;
        }
      }

      .label {
        display: flex;
        font-size: 16px;
        color: var(--text-color);
        margin-bottom: 10px;
        display: block;
      }

      input {
        width: 100%;
        padding: 10px;
        height: 43px;
        border: none;
        border-radius: 10px;
        padding-left: 30px;
        background-color: var(--background-color-2);
        color: var(--text-color);

        &:focus {
          outline: none;
        }
      }
      &__desc {
        align-items: center;
        font-size: 11px;
      }
    }
  }
}
