.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px 15px 0 15px;
  z-index: 10;
  background-color: var(--background-color);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  transform: translateY(-100%);
  transition: transform 0.45s ease-out;
  white-space: nowrap;

  &.visible {
    transform: translateY(0);
  }
}

.fixed-navbar-list {
  display: flex;
  width: 100vw;
  overflow-x: auto;
  list-style-type: none;
  margin: 0;
  padding-right: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.fixed-navbar-item {
  padding-bottom: 10px;
  cursor: pointer;

  &:last-child {
    padding-right: 15px;
  }

  .fixed-navbar-link {
    border-radius: 20px;
    background: none;
    border: none;
    text-decoration: none;
    color: var(--text-color);
    padding: 5px 10px;
    display: inline-block;
    cursor: pointer;
    font: inherit;
    transition: background-color 0.3s ease, color 0.2s ease;
  }

  &.activeCat .fixed-navbar-link {
    color: #000;
    background-color: #eae6e6;
  }
}
