.modal {
  position: absolute;
  bottom: 0;
  max-height: 90vh;
  background-color: var(--background-color);
  padding: 16px;
  width: 100%;
  border-radius: 26px 26px 0 0;
  outline: none;

  .modal-item {
    &__image {
      position: relative;
      border-radius: 16px 16px 0 0;
      z-index: 0;
      overflow: hidden;
      height: 50vh;
      width: 100vw;
      margin-left: calc(-1 * var(--modal-padding, 16px));
      margin-bottom: 15px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: filter 0.3s ease;

        [data-theme='dark'] & {
          filter: brightness(0.65);
        }
      }

      &__name {
        font-size: 1.1em;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background-color: var(--primary-color);
        color: #fff;
        padding: 6px 0;
        box-sizing: border-box;
        border-radius: 25px 25px 0 0;
      }
    }

    &__desc {
      display: flex;
      padding: 5px;
      align-items: center;
      justify-content: space-between;

      .desc {
        flex: 4;
        display: flex;
        flex-direction: column;

        .containts {
          color: var(--text-color);
          font-size: 0.8em;
        }

        .item_desc {
          flex-wrap: wrap;
          font-size: 0.7em;
          color: #7f7e7e;
          white-space: normal;
        }
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      font-size: 1.2em;
    }

    &__card {
      &__btn {
        display: flex;
        align-items: center;
        background-color: var(--primary-color);
        color: white;
        border-radius: 14px;
        font-size: 16px;
        margin-bottom: 15px;
        font-weight: 600;
        padding: 5px;

        &__minus,
        &__plus {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          background-color: rgba(255, 255, 255, 0.15);
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.1s, transform 0.1s ease;

          &:active {
            background-color: rgba(255, 255, 255, 0.25);
            transform: scale(0.95);
          }
        }

        &__text {
          flex: 2;
          flex-direction: column;
          padding: 4px 0;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          cursor: pointer;
          gap: 3px;

          &:active {
            opacity: 0.8;
          }

          .count {
            font-size: 0.9em;
            gap: 4px;
            display: flex;
            align-items: center;
          }

          .add {
            font-size: 0.6em;
          }
        }
      }
    }
  }
}

.hr {
  position: relative;
  border-bottom: 1px solid var(--hr-color);
}
