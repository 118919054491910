.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  color: #333;
  background-color: #f8f9fa;
}

.error-message {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-details {
  font-size: 18px;
  margin-bottom: 20px;
}

.reload-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #42b748;
  color: #fff;
  cursor: pointer;
}
