.search {
  padding: 3px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &__input {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    font-size: 1em;
    transition: border-color 0.3s ease;
    color: var(--text-color);
    background-color: var(--background-color-4);

    &:focus {
      border-color: var(--text-color);
      outline: none;
    }

    &::placeholder {
      color: var(--text-color);
    }
  }

  &__clear-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    color: rgba(233, 76, 55, 0.685);
    cursor: pointer;
  }
}
