@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
  color: inherit;
}
:root {
  --primary-color: #4caf50;
  --secondary-color: #c8e6c9;
  --hover-color: #388e3c;
  --background-color: #ffffff;
  --background-color-2: #f5f5f5;
  --background-color-3: #e2e2e2;
  --background-color-4: #f7f7f7;
  --text-color: #1f311e;
  --text-color-2: #1f311e80;
  --hr-color: #1f311e26;
  --border-color: #858482;
}

[data-theme='dark'] {
  --primary-color: #3c903f;
  --secondary-color: #33691e;
  --hover-color: #689f38;
  --background-color: #1f1f1f;
  --background-color-2: #2a2a2a;
  --background-color-3: #4a4a4a;
  --background-color-4: #2a2a2a;
  --text-color: #e2e2e2;
  --text-color-2: #e0e0e0b4;
  --border-color: #5b5b5b;
  --hr-color: #6d6d6d;
}

body {
  background-color: var(--background-color);
  transition: background-color 1s ease;
}

.wrapper {
  min-width: 100%;
  min-height: 100vh;
  background-color: var(--background-color);
  transition: background-color 1s ease;
}

main {
  padding: 0 15px;
  background-color: var(--background-color);
  transition: background-color 1s ease;
}

.rubl {
  font-size: 0.9em;
}
