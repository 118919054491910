.cart-counter {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1px;
  right: -4px;
  background-color: #42b748;
  color: white;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
}
