.sizes {
  display: flex;
  position: relative;
  z-index: 10;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
  gap: 7px;

  .size {
    flex: 1;
    max-width: calc(50% - 5px);
    text-align: center;
    padding: 6px 4px;
    border-radius: 10px;
    border: 1px solid #b1b1b1;
    min-width: 70px;
    color: #1f311e;
    cursor: pointer;
    transition: all 0.5s ease;

    &:hover {
      background-color: var(--background-color-2);
    }

    &:active {
      transform: scale(0.95);
      transition: transform 0.1s ease;
    }

    &.selected {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      transform: scale(1.05);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

      .price,
      .name {
        color: #fff;
      }
    }

    .name {
      font-size: 12px;
      font-weight: 600;
      color: var(--primary-color);
    }

    .price {
      font-size: 10px;
      color: var(--text-color);
      margin-top: 2px;

      .rubl {
        font-weight: normal;
        font-size: 8px;
      }
    }
  }
}
