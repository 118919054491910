.main-page {
  display: flex;
  flex-direction: column;

  &__title {
    color: var(--text-color);
    font-size: 24px;
    font-weight: 700;
    padding: 10px 0 20px 5px;
    transition: color 1s ease;
  }

  .cafes-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 15px;
  }

  .cafe-item {
    display: block;
    border-radius: 15px;
    background-color: var(--background-color-2);
    transition: transform 1s ease, background-color 1s ease;
    width: 100%;
    height: auto;
    position: relative;

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    }

    &__info {
      width: 100%;
      padding: 10px 8px;
      display: flex;
      flex-direction: column;
      height: auto;

      .cafe-image-block {
        position: relative;
        width: 100%;
        height: 150px;

        .cafe-image {
          border-radius: 15px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
          transition: filter 1s ease, background-color 1s ease;

          [data-theme='dark'] & {
            filter: brightness(0.8);
          }

          &__time {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.6);
            color: #ffffff;
            right: 10px;
            top: 10px;
            padding: 3px 8px;
            font-size: 9px;
            border-radius: 12px;
            font-weight: 400;
          }
        }
      }

      &__desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
        padding: 0 5px;

        .name_and_rating {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          .name {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: var(--text-color);
            transition: color 1s ease;
            position: relative;

            .cafe-status {
              position: absolute;
              top: 20px;
              left: 0;
              color: rgb(210, 9, 9);
              font-weight: 500;
              margin-top: 5px;
              font-size: 0.6rem;
            }
          }

          .rating {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: var(--text-color-2);
            transition: color 1s ease;
            font-size: 9px;
            gap: 1px;
            margin-top: 1px;
            padding: 2px;
            font-weight: normal;

            .rating__up {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 1px;
              font-weight: 600;

              &__icon {
                display: flex;
                align-items: flex-end;
              }

              &__count {
                align-items: flex-end;
                padding-top: 1px;
              }
            }
            &__hr {
              width: 120%;
              height: 1px;
              border-bottom: 1px solid var(--text-color-2);
              transition: border 1s ease;
            }
          }
        }

        .phone_and_address {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: var(--text-color-2);
          transition: color 1s ease;
          gap: 5px;
          font-size: 10px;
          font-weight: 500;
        }
      }
    }
  }
}
