.menu-item {
  background-color: var(--background-color-2);
  border: 1px solid #e0e0e000;
  border-radius: 16px;
  padding: 10px 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &__image {
    max-height: 140px;
    width: 100%;
    border-radius: 16px 16px 0 0;
    object-fit: cover;
    overflow: hidden;
    transition: filter 0.3s ease;

    [data-theme='dark'] & {
      filter: brightness(0.7);
    }
  }

  &__name {
    font-size: 0.8em;
    font-weight: 400;
    margin-bottom: 8px;
    color: var(--text-color);
    line-height: 19px;
    padding: 0px 5px;
  }

  &__price {
    font-weight: 600;
    color: var(--text-color);
    padding: 10px 5px 0 5px;

    &__from {
      font-size: 0.9em;
      color: var(--hover-color);
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 0 5px;
    height: 30px;
    background-color: var(--background-color);
    border-radius: 25px;
    border: none;
    margin-top: auto;
    cursor: pointer;
    color: var(--text-color);

    &__add {
      font-size: 22px;
      font-weight: 400;
    }
  }
}
